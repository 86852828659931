.App {
}
a {
  text-decoration: none;
}
.btn {
  border-radius: 3px;
  font-size: 17px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  backdrop-filter: blur(8px);
}
.fs-20px {
  font-size: 18px !important;
}
.fs-18px {
  font-size: 18px !important;
  line-height: 24px;
  color: black;
}
.btn-c1 {
  background-color: #b0b960;
}
.btn-c1:hover {
  background-color: #c8d36a;
}
.btn-info {
  background-color: #31ade8;
  padding: 2px 8px;
}
.custom-contact-btn {
  /* background-color: #31ade8; */
  background: radial-gradient(ellipse at 50% -50%, #6c94ff 0, #000 100%);
  border-radius: 3px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}
.custom-contact-btn:hover {
  /* background-color: #03afff; */
  background: radial-gradient(ellipse at 50% -50%, #4d6ab8 0, #000 100%);
  border-radius: 3px;
  color: #ffffff;
}
.text-c1 {
  color: #7b8522 !important;
}
.mt-6 {
  margin-bottom: 120px;
}
.shadow-c1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.gradient-c1 {
  background: radial-gradient(ellipse at 50% -50%, #6c94ff 0, #000 100%);
}
.gradient-c2 {
  background: radial-gradient(ellipse at 50% -50%, #e2ffe9 0, #000 100%);
}
.gradient-c3 {
  background: radial-gradient(ellipse at 50% -50%, #a06f2e 0, #000 100%);
}
.gradient-c4 {
  background: radial-gradient(ellipse at 50% -50%, #6c94ff 0, #36314b 100%);
}
.gradient-c5 {
  background: radial-gradient(ellipse at 50% -50%, #e2e7f5 0, #b9b8b8 100%);
}
@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.fa-angles-right {
  display: inline-block;
  animation: moveRight 1.8s linear infinite;
}
.fa-angles-left {
  display: inline-block;
  animation: moveRight 1.8s linear infinite;
}
.hero-h {
  text-transform: capitalize;
  letter-spacing: 0.8px;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 40px;
  background-color: #005baa;
  background-image: linear-gradient(45deg, #005baa, #000000);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

/* .........YachtDetails Page........  */
.spec li::before {
  content: "\2022";
  color: #f87408;
  font-weight: bolder;
  display: inline-block;
  width: 1rem;
  margin-left: -1em;
}
.boat-description {
  font-weight: 200;
}
.boat-description p {
  font-size: 17px;
  text-align: start !important;
  margin-bottom: 6px;
}
.boat-description span {
  font-size: 17px;
  text-align: start !important;
  margin-bottom: 6px;
}
.boat-description strong {
  font-size: 17px;
  text-align: start !important;
  margin-bottom: 6px;
}
.boat-description li {
  list-style: disc;
}
.Featured-scroll-container {
  max-height: 700px; /* Adjust the height as needed */
  overflow-y: scroll;
}

.Featured-scroll-container::-webkit-scrollbar {
  width: 3px; /* Adjust the width as desired */
}

.Featured-scroll-container::-webkit-scrollbar-thumb {
  background-color: #ffc400; /* Change the thumb color */
  border-radius: 5px; /* Round the thumb edges */
}

.Featured-scroll-container::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Change the track color */
}
/********** Effect #1 CSS *********/
.effect-1 {
  position: relative;
  overflow: hidden;
}

.effect-1 .effect-img {
  font-size: 0;
}

.effect-1 .effect-img img {
  width: 100%;
  /* height: auto; */
}

.effect-1 .effect-text {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 30px;
  top: calc(100% - 60px);
  left: 0;
  text-align: start;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

/* .effect-1:hover .effect-text {
  top: 0;
} */

.effect-1 .effect-text h2 {
  height: 45px;
  color: #ffffff;
  font-size: 20px;
  margin: -15px 0 0 0;
  transition: 0.5s;
}

/* .effect-1:hover .effect-text h2 {
  margin: 0;
} */

.effect-1 .effect-text p {
  color: #ffffff;
  font-size: 16px;
  margin: 0 0 20px 0;
}

.effect-1 .effect-btn .btn {
  display: inline-block;
  height: 35px;
  padding: 7px 15px;
  color: #333333;
  background: #ffffff;
  text-decoration: none;
}
.c-contact-us {
  /*---------Contact---------*/
  .PhoneInputInput {
    padding: 6px 12px;
    border-width: 1px;
    border-style: solid;
    border-color: #dee2e6;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out;
  }
  .PhoneInputInput:focus {
    outline-width: 4px;
    outline-style: solid;
    outline-color: #9ec5fe;
    opacity: 0.8;
  }

  .form-control,
  .form-control_select {
    font-size: 14px;
  }
  .form-text {
    font-size: 10px;
    color: #0052cc;
  }
}
/* ................................  */
/* Default styles for all screen sizes */
.details-banner .detailed-swiper-slide {
  width: auto;
  height: 250px; /* Default height for all screens */
}

.details-banner .detailed-swiper-slide img {
  width: auto;
  height: 100%;
}

/* Media query for tablets (e.g., iPad) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .details-banner .detailed-swiper-slide {
    height: 300px; /* Tablet height */
  }
}

/* Media query for desktop screens */
@media only screen and (min-width: 1024px) {
  .details-banner .detailed-swiper-slide {
    height: 400px; /* Desktop height */
  }
}
/* ...................  */
/* ...................  */
@media (max-width: 768px) {
  .custom-modal {
    width: 100% !important; /* Adjust the width as needed */
  }
}
/* Styles for the popup container */
#popup-container {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 20px; */
  border-radius: 5px;
  background-color: #fff6f6;
  /* border: 1px solid #ccc; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 99999;
}
#popup-container h5 {
  color: #000;

  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
#popup-container img {
  max-height: 500px;
  width: 100%;
}

/* Styles for the close button */
#close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 99999;
}

/* Responsive styles for small screens */
@media (min-width: 200px) {
  #popup-container {
    width: 90%;
  }
}

/* Responsive styles for larger screens */
@media (min-width: 400px) {
  #popup-container {
    width: 80%;
  }
}

/* Responsive styles for even larger screens */
@media (min-width: 992px) {
  #popup-container {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .custom-modal {
    width: 85% !important;
  }
}
/* Default styles for all screen sizes */
.details-popup .detailed-swiper-popup {
  width: auto;
  height: 250px; /* Default height for all screens */
}

.details-popup .detailed-swiper-popup img {
  width: auto;
  height: 100%;
}

/* Media query for tablets (e.g., iPad) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .details-popup .detailed-swiper-popup {
    height: 300px; /* Tablet height */
  }
}

/* Media query for desktop screens */
@media only screen and (min-width: 1024px) {
  .details-popup .detailed-swiper-popup {
    height: 500px; /* Desktop height */
  }
}
.swiper-pagination-bullet {
  background-color: #e4a11b;
  color: #fff; /* Add this line if you want to change the color of the pagination text */
}
/* Default styles for all screen sizes */
.details-popup1 .detailed-swiper-popup1 {
  width: auto;
  height: 250px; /* Default height for all screens */
}

.details-popup1 .detailed-swiper-popup1 img {
  width: auto;
  height: 100%;
}

/* Media query for tablets (e.g., iPad) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .details-popup1 .detailed-swiper-popup1 {
    height: 400px; /* Tablet height */
  }
}

/* Media query for desktop screens */
@media only screen and (min-width: 1024px) {
  .details-popup1 .detailed-swiper-popup1 {
    height: 500px; /* Desktop height */
  }
}
/*------Footer---------*/
footer ul {
  list-style: none;
  text-decoration: none;
}
.footer-items li:first-child {
  border-top: 1px solid rgb(80, 95, 121);
  margin-top: 1rem;
  padding: 1rem 0px;
}
.footer-items li {
  border-bottom: 1px solid rgb(80, 95, 121);
  padding: 0px 0px 1rem;
  margin: 0px 0px 1rem;
}
.c-footer-links a {
  align-items: center;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  display: flex;
  height: 24px;
  width: 24px;
  margin: 0px !important;
}
.c-footer-links a svg {
  height: 60%;
  transition: all 0.15s ease 0s;
  width: 60%;
  margin: auto !important;
}
@media only screen and (min-width: 1024px) {
  .footer-items li:first-child {
    border-top: none;
    margin-top: 0rem;
    padding: 0rem 0px;
  }
  .footer-items li {
    border: none;
    padding: 0px;
    margin: 0px;
    width: 25%;
  }
  .footer-items li:hover {
    background-color: rgb(64, 93, 145);
  }
  .width-lg-100 {
    width: 100%;
  }
  .footer-items {
    padding: 0 1rem;
  }
  .border-footer {
    border-top: 1px solid rgb(80, 95, 121);
    width: 98%;
    margin: 0px auto;
  }
}
/* ............................  */
/* .offcanvas {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(45deg, #005baa, #000000);
  transition: transform 0.5s ease-in-out;
  z-index: 1050;
  transform: translateY(100%);
}

.offcanvas.show {
  transform: translateY(0);
}

.offcanvas-content {
  position: relative;
  background-image: linear-gradient(45deg, #005baa, #000000);
  transition: transform 0.5s ease-in-out;
  padding: 10px;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

.offcanvas-body {
  flex-grow: 1;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #ffffff;
} */
.offcanvas {
  position: fixed;
  bottom: 20px; /* Adjusted to move the notification slightly up */
  left: 20px; /* Positioned to the bottom left */
  width: 300px;
  max-width: 90%;
  padding: 15px;
  background-image: linear-gradient(45deg, #005baa, #000000);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  z-index: 1050;
  opacity: 0;
  visibility: hidden;
}

.offcanvas.show {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.offcanvas-content {
  position: relative;
  padding: 10px;
  background-image: linear-gradient(45deg, #005baa, #000000);
  transition: transform 0.5s ease-in-out;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.offcanvas-body {
  flex-grow: 1;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #ffffff;
  align-self: flex-end;
}
/* detailed page video  */

/* Base styles for .video-box */
.video-box {
  min-width: 600px;
  height: auto;
}

/* For mobile phones (up to 600px) */
@media (max-width: 600px) {
  .video-box {
    min-width: 300px;
    height: auto;
  }
}

/* For tablets (601px to 900px) */
@media (min-width: 601px) and (max-width: 900px) {
  .video-box {
    min-width: 600px;
    height: auto;
  }
}

/* For desktops (901px and above) */
@media (min-width: 901px) {
  .video-box {
    min-width: 500px;
    height: auto;
  }
}

.instagram-content {
  height: 200px; /* Adjust as needed */
  overflow: hidden;
  position: relative;
}

.instagram-content video {
  height: 100%;
  width: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Crops overflow */
}
