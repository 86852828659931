.banner {
  position: relative;
}

.banner-content {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* Aspect ratio 16:9 */
}

.banner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner-text {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

@media (max-width: 768px) {
  .banner-text h2 {
    font-size: 24px;
  }
}
/* Only Mobile  */
.mob-banner {
  position: relative;
  width: 100%;
  height: 56.25vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
  .mob-banner {
    position: relative;
    width: 100%;
    height: 150.25vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    overflow-x: hidden;
  }
  .mob-banner-text {
    z-index: 1;
    font-size: 1.5em;
    margin-bottom: 0;
    font-family: "Merianda";
    color: white;
    text-align: center;
  }
}

/* ---------- Animation --------------- */
@keyframes fade {
  0% {
    opacity: 0;
    margin-left: 3em;
  }
  100% {
    opacity: 1;
  }
}
