@import url("https://fonts.googleapis.com/css2?family=Merriweather&family=Poppins&family=Roboto&display=swap");

body {
  margin: 0;
  font-family: "Poppins", Roboto Slab, sans-serif;
}

code {
  font-family: "Poppins", Roboto Slab, sans-serif;
}
