.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 13px;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  z-index: 5;
}

.navbar.scrolling {
  background: radial-gradient(ellipse at 50% -50%, #6c94ff 0, #000 100%);
}

@media (max-width: 800px) {
  .navbar {
    padding: 13px;
    background: radial-gradient(ellipse at 50% -50%, #6c94ff 0, #000 100%);
  }
}

/* Add other styles as needed */
.logo {
  font-size: 24px;
  color: white;
}
